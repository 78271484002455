:root{
    --cc-white: #ffffff;

    // Black
    --cc-black: #000000;  
    --cc-black-1: #1C1B1F;
    --cc-grey-bg: #FAFAFA;  
    --cc-grey-1: #333333;
    --cc-grey-2: #4F4F4F;
    --cc-grey-21: #646464;
    --cc-grey-3: #828282;
    --cc-grey-41: #9F9F9F;
    --cc-grey-5: #E0E0E0;
    --cc-grey-6: #F2F2F2;
    --cc-grey-7: #BDBDBD;
    --cc-grey-8: #ECEFF2;
    --cc-grey-9: #F7F7F7;
    --cc-grey-20: #999999;
    --cc-grey-30: #C9C9C9;
    --cc-grey-40: #E9E9E9;

    // Green
    --cc-green-1: #18C864;
    --cc-green-2: #5D8051;
    --cc-green-3: #83D367;
    --cc-green-4: #CDEDC2;
    --cc-green-5: #03935e;
    --cc-green-6: #219653;
    --cc-green-6: #EFFEF0;
    --cc-green-7: #E8FAF0; 
    --cc-green-8: #03935E;

    --cc-green-badge: #03935E;
    --cc-green-badge-b: #E8FAF0; 

    // Blue
    --cc-blue-1: #0C0C60;
    --cc-blue-2: #0076C5;
    --cc-blue-3: #C2E4FA;
    --cc-blue-4: #B5E4FF;
    --cc-blue-5: #45BCFF;
    --cc-blue-btn: #3A0CA3;
    --cc-blue: #3A0CA3;
    --cc-blue-light: #D3C7F2;


    // Purple
    --cc-purple-1: #7943A7;
    --cc-purple-2: #C8A5E6;
    --cc-purple-3: #E9DBF5;

    // Red
    --cc-red-1: #B32D3A;
    --cc-red-2: #E33A35;
    --cc-red-3: #FF6767;
    --cc-red-4: #FF7575;
    --cc-red-5: #F9BCB0;
    --cc-red-6: #FE5353;
    --cc-red: #EB5757;
    --cc-red-cal: #FFE5E5;

    // Pink
    --cc-pink-1: #F92B8B;  
    
      // Orange
      --cc-orange-1: #FE9701; 
      --cc-orange-2: #D97B27; 

      --cc-clr-a:#CDEDC2;
      --cc-clr-ua:#F9BCB0;
      --cc-clr-b:#C2E4FA;
      --cc-clr-be:#E9DBF5;
      --cc-clr-hi:#D6D6D6;

      // White
      --cc-white:#ffffff;
      // badge and global colours orange
      --bg-l-orange:#FFF5E6;
      --text-orange:#FE7301;

}
