// Arimo Family

// Arimo Regular - 400
@font-face  {   font-family: 'arimo-regular';src: url('../fonts/Arimo/static/Arimo-Regular.ttf') format("truetype");font-display: auto;}

// Arimo Regular Italic - 400
@font-face  {   font-family: 'arimo-regular-i';src: url('../fonts/Arimo/static/Arimo-Italic.ttf') format("truetype");font-display: auto;}

// Arimo Medium - 500
@font-face  {   font-family: 'arimo-medium';src: url('../fonts/Arimo/static/Arimo-Medium.ttf') format("truetype");font-display: auto;}

// Arimo Medium Italic - 500
@font-face  {   font-family: 'arimo-medium-i';src: url('../fonts/Arimo/static/Arimo-MediumItalic.ttf') format("truetype");font-display: auto;}

// Arimo SemiBold - 600
@font-face  {   font-family: 'arimo-semibold';src: url('../fonts/Arimo/static/Arimo-SemiBold.ttf') format("truetype");font-display: auto;}

// Arimo SemiBold Italic - 600
@font-face  {   font-family: 'arimo-semibold-i';src: url('../fonts/Arimo/static/Arimo-SemiBoldItalic.ttf') format("truetype");font-display: auto;}

// Arimo Bold - 700
@font-face  {   font-family: 'arimo-bold';src: url('../fonts/Arimo/static/Arimo-Bold.ttf') format("truetype");font-display: auto;}

// Arimo Bold Italic - 700
@font-face  {   font-family: 'arimo-bold-i';src: url('../fonts/Arimo/static/Arimo-BoldItalic.ttf') format("truetype");font-display: auto;}

// Poppins Regular - 400
@font-face  {   font-family: 'poppins_r';src: url('../fonts/Poppins/Poppins-Regular.ttf') format("truetype");font-display: auto;}

// Poppins Bold
@font-face  {   font-family: 'poppins_b';src: url('../fonts/Poppins/Poppins-Bold.ttf') format("truetype");font-display: auto;}

// Poppins Medium
@font-face  {   font-family: 'poppins_m';src: url('../fonts/Poppins/Poppins-Medium.ttf') format("truetype");font-display: auto;}
@font-face  {   font-family: 'poppins_sb';src: url('../fonts/Poppins/Poppins-SemiBold.ttf') format("truetype");font-display: auto;}
