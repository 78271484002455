// Mixins
// Font Family
@mixin font-source-arimo($family: false,
  $size: false,
  $colour: false,
  $lh: false) {
  @if $family {
    font-family: $family !important;
  }

  @if $size {
    font-size: $size !important;
  }

  @if $colour {
    color: $colour !important;
  }

  @if $lh {
    line-height: $lh !important;
  }
}

// Font Family
@mixin font-source-poppins($family: false,
  $size: false,
  $colour: false,
  $lh: false) {
  @if $family {
    font-family: $family !important;
  }
}

// Margin and padding
$max: 50;
$offset: 1;
$unit: "px"; // Feel free to change the unit.

@mixin list-loop($className, $styleName) {
  $i: 0;

  @while $i <=$max {
    #{$className + $i} {
      #{$styleName}: #{$i + $unit} !important;
    }

    $i: $i + $offset;
  }
}

// Margins
@include list-loop(".mt-", "margin-top");
@include list-loop(".mr-", "margin-right");
@include list-loop(".mb-", "margin-bottom");
@include list-loop(".ml-", "margin-left");
@include list-loop(".mx-", "margin");
// Paddings
@include list-loop(".pt-", "padding-top");
@include list-loop(".pr-", "padding-right");
@include list-loop(".pb-", "padding-bottom");
@include list-loop(".pl-", "padding-left");
@include list-loop(".px-", "padding");
@include list-loop(".width-", "width");

// Paddings
@include list-loop(".fs-", "font-size");

// BOrder radius
@include list-loop(".br-", "border-radius");

// Center Elements
@mixin center($position) {
  position: absolute;

  @if $position =="vertical" {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  @else if $position =="horizontal" {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translate(-50%);
  }

  @else if $position =="both" {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

// @include center(both);

// Media Query
$breakpoints: (
  "phone": 400px,
  "phone-wide": 480px,
  "phablet": 560px,
  "tablet-small": 640px,
  "tablet": 768px,
  "tablet-wide": 1024px,
  "desktop": 1248px,
  "desktop-wide": 1440px,
);

@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);

    @if $type ==max {
      $width: $width - 1px;
    }

    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

// @include mq('tablet-wide') {
//     padding-top: 4rem;
//     font-size: 2.4rem;
// }

// ____________________________________________________________________________________________________________________ //