/* You can add global styles to this file, and also import other style files */
@import "./assets/css/variables";
@import "./assets/css/fonts.scss";
@import "./assets/css/mixins.scss";
@import "@ng-select/ng-select/themes/default.theme.css";
body {
  font-family: "arimo-regular" !important;
}


.c_input {
  align-self: flex-start;
}

.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  user-select: none;
  width: fit-content !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 10px 15px 10px 36px !important;
  width: auto !important;
  border-radius: 10px !important;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 14px !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
}

.toast-top-right {
  top: unset !important;
  right: 25px !important;
  left: 25px !important;
  bottom: 10px !important;
}

.toast-success {
  background-color: #ffffff !important;
  color: #4f4f4f !important;
}

.toast-error {
  background-color: #ff0d00 !important;
  color: #ffffff !important;
}

span {
  overflow-wrap: anywhere;
}

.ngx-loading-logo {
  width: 40px !important;
}

.toast-success {
  background-image: url("./assets/images/success.png") !important;
}

.toast-message {
  word-wrap: break-word;
  font-size: 14px;
}

.toast-container .ngx-toastr:hover {
  box-shadow: unset;
  opacity: 1;
  cursor: pointer;
}

.toast-close-button {
  position: absolute !important;
  right: 16px !important;
  top: 5px !important;
  float: right;
  font-size: 20px !important;
  /* font-weight: bold; */
  color: #ffffff;
  text-shadow: unset !important;
  opacity: 0.8;
}

.toast-container {
  width: auto !important;
  max-width: 50%;
  margin: auto !important;
  pointer-events: none;
}

.float-right {
  float: right !important;
}

.no-invite-image {
  width: 100%;
}

.place-content-end {
  place-content: flex-end;
}

@media only screen and (min-width: 998px) {
  .body-width {
    padding: 0px 15px;
  }
}

@media only screen and (min-width: 1300px) {
  .body-width {
    width: 90%;
    padding: 0px 15px;
  }
}

// Mixins
// Font Family
@mixin font-source-arimo($family: false,
  $size: false,
  $colour: false,
  $lh: false) {
  @if $family {
    font-family: $family;
  }

  @if $size {
    font-size: $size;
  }

  @if $colour {
    color: $colour;
  }

  @if $lh {
    line-height: $lh;
  }
}

// Font Family
@mixin font-source-poppins($family: false) {
  @if $family {
    font-family: $family !important;
  }
}

// Margin and padding
$max: 50;
$offset: 1;
$unit: "px"; // Feel free to change the unit.

@mixin list-loop($className, $styleName) {
  $i: 0;

  @while $i <=$max {
    #{$className + $i} {
      #{$styleName}: #{$i + $unit};
    }

    $i: $i + $offset;
  }
}

// Margins
@include list-loop(".mt-", "margin-top");
@include list-loop(".mr-", "margin-right");
@include list-loop(".mb-", "margin-bottom");
@include list-loop(".ml-", "margin-left");
@include list-loop(".mx-", "margin");
// Paddings
@include list-loop(".pt-", "padding-top");
@include list-loop(".pr-", "padding-right");
@include list-loop(".pb-", "padding-bottom");
@include list-loop(".pl-", "padding-left");
@include list-loop(".px-", "padding");

// Center Elements
@mixin center($position) {
  position: absolute;

  @if $position =="vertical" {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  @else if $position =="horizontal" {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translate(-50%);
  }

  @else if $position =="both" {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

// @include center(both);

// Media Query
$breakpoints: (
  "phone": 400px,
  "phone-wide": 480px,
  "phablet": 560px,
  "tablet-small": 640px,
  "tablet": 768px,
  "tablet-wide": 1024px,
  "desktop": 1248px,
  "desktop-wide": 1440px,
);

@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);

    @if $type ==max {
      $width: $width - 1px;
    }

    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

// @include mq('tablet-wide') {
//     padding-top: 4rem;
//     font-size: 2.4rem;
// }

// ____________________________________________________________________________________________________________________ //

// Card Designs

// ____________________________________________________________________________________________________________________ //
.ng-select.ng-select-single .ng-select-container {
  height: 37px;
}

// #calendar-type{
//   .ng-select.ng-select-single .ng-select-container{
//       background-color: #fafafa !important;
//   }
// }

.ng-dropdown-panel.ng-select-bottom {
  top: 100%;
  border-radius: 4px;
  border-top-color: #d9d9d9;
  margin-top: 1px !important;
}

.ng-dropdown-panel {
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  left: 0;
}

// Muliselect
.request-type {
  .ng-dropdown-panel.ng-select-top {
    width: max-content;
  }
}

.ng-dropdown-panel.ng-select-top {
  bottom: 100%;
  border-radius: 4px;
  border-top-color: #d9d9d9;
  margin-bottom: 1px !important;
}

.form-control {
  .ng-dropdown-panel.ng-select-bottom {
    width: max-content;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items {
    padding: 5px;
  }

  .form-floating .ng-select.ng-select-single .ng-select-container {
    height: 0px;
    min-height: 21px;
    border: none !important;
    background: none !important;
  }

  .form-floating .ng-select .ng-select-container .ng-value-container {
    padding: 0px !important;
  }

  .ng-input {
    top: 0px !important;
  }

  .ng-select {
    .ng-option {
      padding: 5px !important;
    }
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input>input {
  color: var(--cc-grey-2) !important;
}

.ng-select {
  .ng-select-container {
    border: 1px solid var(--cc-grey-30) !important;
    border-radius: 10px !important;
    color: var(--cc-grey-3) !important;
    min-height: 37px;
  }

  .ng-select-multiple {
    .ng-option {
      @include font-source-arimo("arimo-regular", 14px, var(--cc-grey-1));
      padding: 10px 10px 10px 23px !important;
    }
  }

  .ng-option {
    @include font-source-arimo("arimo-regular", 14px, var(--cc-grey-1));
    padding: 8px 10px 8px 13px !important;
  }

  label {
    font-size: 14px;
    margin-left: 10px;
    margin-bottom: 0px;
    color: #828282;
  }

  .ng-optgroup {
    @include font-source-arimo("arimo-regular", 14px, var(--cc-grey-1));
    // background-color: var(--cc-grey-40);
    padding: 10px 10px 10px 10px !important;
  }

  .ng-option-marked {
    background-color: #f2f2f2 !important;
  }

  .ng-value-icon.left {
    border-right: 1px solid #b0b0b0 !important;
  }

  .ng-value-icon:hover {
    background-color: #b0b0b0 !important;
  }

 
}

#custom-group-ng {
  .ng-option-disabled {
    @include font-source-arimo("arimo-semibold", 14px, var(--cc-grey-1));
    background-color: #f1ecff !important;
  }
}

.fw-5 {
  font-weight: 500;
}

// Form Controls
.form-label {
  margin-bottom: 0rem !important;
  @include font-source-arimo("arimo-medium", 16px, var(--cc-grey-2));
}

.form-floating {

  .form-control,
  .form-select {
    background-color: var(--cc-white) !important;
    border: 1px solid var(--cc-grey-30) !important;
    border-radius: 10px !important;
    @include font-source-arimo("arimo-regular", 14px, var(--cc-grey-1));
    height: 46px !important;
  }

  .form-control:focus~label,
  .form-control:not(:placeholder-shown)~label,
  .form-select~label {
    transform: unset !important;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: var(--cc-grey-6) !important;
  opacity: 1;
}

.form-control,
.form-select {
  background-color: var(--cc-white) !important;
  border: 1px solid var(--cc-grey-30) !important;
  border-radius: 10px !important;
  @include font-source-arimo("arimo-regular", 14px, var(--cc-grey-1));
  height: 37px;
}

textarea.form-control {
  min-height: calc(4.5em + 0.75rem + 2px);
}

.ng-select .ng-select-container .ng-value-container {
  @include font-source-arimo("arimo-regular", 14px, var(--cc-grey-1));
}

.ng-placeholder {
  @include font-source-arimo("arimo-regular", 14px, var(--cc-grey-2));
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  @include font-source-arimo("arimo-regular", 14px, var(--cc-grey-2));
}

// Removed Bootstrap Outlines and shadows when hover and focus
textarea:focus,
select:focus,
button:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
input[type="checkbox"]:focus,
[contenteditable].form-control:focus {
  box-shadow: none !important;
}

textarea:focus,
select:focus,
button:focus,
input[type="text"]:focus,
.form-control:focus {
  box-shadow: 0 0 0 0.09rem rgb(249 43 139);
}

.form-control:focus {
  box-shadow: none;
}

// Card
.card {
  background-color: var(--cc-white);
  border: 1px solid var(--cc-grey-40);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
}

// Checkbox
.form-check-input {
  width: 19px !important;
  height: 19px !important;
  background-color: var(--cc-grey-9) !important;
  border: 1px solid var(--cc-grey-7) !important;
  border-radius: 3px !important;
}

.form-check-input:hover {
  background-color: #f9f1fe !important;
}

.form-check-input:active {
  background-color: #f9f1fe !important;
}

.s_tag {
  background: var(--cc-green-7);
  @include font-source-arimo("arimo-regular", 12px, var(--cc-green-8));
  padding: 4px 8px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

// .form-switch .form-check-input {
//   width: 19px !important;
//   height: 19px !important;
//   background-color: var(--cc-grey-7) !important;
//   border: 1px solid var(--cc-grey-7) !important;
//   border-radius: 3px !important;
// }

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-switch .form-check-input {
  width: 2em;
  // margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
  background-color: var(--cc-grey-7) !important;
  border: 1px solid var(--cc-grey-7) !important;
}

.form-check-input:checked {
  background-color: var(--cc-blue-btn) !important;
  border: 1px solid var(--cc-blue-btn) !important;
}

.secondary.form-check-input:checked {
  background-color: var(--cc-grey-3) !important;
  border: 1px solid var(--cc-grey-3) !important;
}

.form-check-label {
  @include font-source-arimo("arimo-regular", 14px, var(--cc-grey-2));
  margin-top: 4px;
}

// Badges
.badges-cc {
  background-color: var(--cc-grey-6) !important;
  border-radius: 16.1904px !important;
  @include font-source-arimo("arimo-regular", 11.3333px, var(--cc-grey-2));
}

// HR
hr {
  border: 1px solid var(--cc-grey-30);
}

// Switches/Toggles
/* Rounded sliders */
.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 22px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--cc-grey-7);
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 15.51px;
      left: 4px;
      top: 2.5px;
      background-color: var(--cc-white);
      transition: 0.4s;
    }
  }

  input {
    &:checked {
      + {
        .slider {
          background-color: var(--cc-blue-btn);

          &:before {
            -webkit-transform: translateX(18px);
            -ms-transform: translateX(18px);
            transform: translateX(18px);
          }
        }
      }
    }

    &:focus {
      + {
        .slider {
          box-shadow: 0 0 1px #2196f3;
        }
      }
    }
  }

  .slider.round {
    border-radius: 34px;

    &:before {
      border-radius: 50%;
    }
  }
}

.input-group {
  .form-control {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .input-group-text {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
    height: 37px;
    background-color: #ffffff;
  }
}

.form-floating>.form-control,
.form-floating>.form-select {
  height: calc(36px + 2px);
  line-height: 1.25;
}

.form-floating {
  .ng-select.ng-select-single .ng-select-container {
    height: 46px;
  }

  .ng-select .ng-select-container .ng-value-container {
    padding: 0px 12px;
    padding-top: 12px;
  }
}

.form-floating>label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  @include font-source-arimo("arimo-regular", 11px, var(--cc-grey-3));
  padding: 2px 12px;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
  opacity: 1;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.hover-pink:hover {
  color: #f92b8b !important;
}

.h_b:hover {
  color: var(--cc-blue-btn) !important;
}

.h_b_ul:hover {
  color: var(--cc-blue-btn) !important;
  text-decoration: underline;
}

.btn-cc-red {
  color: var(--cc-red) !important;
}

.clr-cc-vilot {
  color: var(--cc-blue-btn) !important;
}

// Buttons
.btn-pink {
  background: var(--cc-pink-1);
  border-radius: 10px;
  @include font-source-arimo("arimo-bold", 12px, var(--cc-white));
}

.cc-blue-btn {
  background: var(--cc-blue-btn);
  border-radius: 8px;
  @include font-source-arimo("arimo-bold");
  color: var(--cc-white) !important;
  padding: 7px 12px;
  height: 42px;
}

.cc_s_btn {
  background: var(--cc-blue-btn);
  border-radius: 8px;
  @include font-source-arimo("arimo-bold");
  color: var(--cc-white);
  padding: 7px 12px !important;
  height: 40px;
  cursor: pointer;
}

.cc_p_btn {
  background: var(--cc-blue-btn) !important;
  border-radius: 8px;
  @include font-source-arimo("arimo-bold");
  color: var(--cc-white) !important;
  padding: 7px 20px !important;
  height: 40px;
  padding: 10px 10px;
  cursor: pointer;
}

.align-justify {
  text-align: justify;
}

.btn:hover {
  color: unset;
}

.fs_16 {
  font-size: 16px;
}

.fs_15 {
  font-size: 15px;
}

.fs_14 {
  font-size: 14px;
}

.fs_12 {
  font-size: 12px;
}

.fs_13 {
  font-size: 13px;
}

.cc-blue-btn.btn {
  padding: 7px 12px;
}

.btn-cc-secondary {
  background: var(--cc-grey-6);
  border-radius: 10px;
  @include font-source-arimo("arimo-bold", 12px, var(--cc-grey-1));
}

// Heading & Para
.user-title {
  @include font-source-arimo("arimo-bold", 16px);
  color: var(--cc-grey-1);
  word-break: break-word;
}

.txt_s_r_b {
  @include font-source-arimo("arimo-bold", 12px);
  color: var(--cc-grey-1);
}

.user-title-m {
  @include font-source-arimo("arimo-medium", 16px, var(--cc-grey-1));
}

.user-sub-title {
  @include font-source-arimo("arimo-medium", 12px, var(--cc-grey-1));
}

.user-sub-sub-title {
  @include font-source-arimo("arimo-medium", 14px, var(--cc-grey-21));
  font-weight: 400;
}

.ul-title {
  @include font-source-arimo("arimo-bold", 18px, var(--cc-black));
}

.black-text-14 {
  @include font-source-arimo("arimo-regular", 14px, var(--cc-black-1));
  font-weight: 400;
  line-height: 16.7px;
}

.black-text-14-b {
  @include font-source-arimo("arimo-bold", 14px, var(--cc-black-1));
  font-weight: 700;
  line-height: 17.09px;
}

.ul-title-medium {
  @include font-source-arimo("arimo-medium", 18px, var(--cc-black));
}

.u_t_r {
  @include font-source-arimo("arimo-regular", 14px);
  color: var(--cc-black);
}

.txt_r_g {
  @include font-source-arimo("arimo-regular", 14px);
  color: var(--cc-grey-3);
}

.txt_r_m_g {
  @include font-source-arimo("arimo-medium", 14px);
  color: var(--cc-grey-1);
}

.u_r_s_g2 {
  @include font-source-arimo("arimo-regular", 12px);
  color: var(--cc-grey-2);
}

.u_m_s_g2 {
  @include font-source-arimo('arimo-regular', 12px);
  font-weight: 500;
  color: var(--cc-grey-2);
}

.ul-title-normal-text {
  @include font-source-arimo("arimo-medium", 14px, var(--cc-black));
}

.txt-para {
  @include font-source-arimo("arimo-regular", 13px, var(--cc-grey-1));
}

.txt-regular {
  @include font-source-arimo("arimo-regular", 14px);
  color: var(--cc-grey-1);
}

.txt-regular-w {
  @include font-source-arimo("arimo-regular", 14px, var(--cc-white));
}

.text-regular-medium {
  @include font-source-arimo("arimo-medium", 14px);
  color: var(--cc-grey-1);
}

.text-r-m-1 {
  @include font-source-arimo("arimo-medium", 14px, var(--cc-grey-1));
}

.complete-match {
  @include font-source-arimo("arimo-medium", 12px, var(--cc-green-5));
}

.partial-match {
  @include font-source-arimo("arimo-medium", 12px, var(--cc-orange-2));
}

.text-s-m-g-3 {
  @include font-source-arimo("arimo-medium", 12px, var(--cc-grey-3));
}

.text-s-m-g-2 {
  @include font-source-arimo("arimo-medium", 12px, var(--cc-grey-2));
}

.text-s-r-g-3 {
  @include font-source-arimo("arimo-regular", 12px, var(--cc-grey-3));
}

.text-s-m-g-31 {
  @include font-source-arimo("arimo-medium", 12px, var(--cc-grey-21));
}

.text-r-g-3 {
  @include font-source-arimo("arimo-regular", 14px, var(--cc-grey-3));
}

.text-regular-medium-gray-light {
  @include font-source-arimo("arimo-medium", 14px, var(--cc-grey-7));
}

.txt-regular-gray {
  @include font-source-arimo("arimo-regular", 14px, var(--cc-grey-41));
}

.text-r-b-18 {
  @include font-source-arimo("arimo-bold", 18px, var(--cc-grey-2));
}

.text-r-18 {
  @include font-source-arimo("arimo-regular", 16px, var(--cc-grey-2));
  color: #646464 !important;
  font-weight: 400 !important;
}

.text-small-bold {
  @include font-source-arimo("arimo-bold", 12px, var(--cc-grey-1));
}

.text-r-m-16 {
  @include font-source-arimo("arimo-medium", 16px, var(--cc-grey-1));
}

.text-regular-bold {
  @include font-source-arimo("arimo-bold");
  font-size: 14px;
  color: var(--cc-grey-1);
}

.text-regular-bold-w {
  @include font-source-arimo("arimo-bold");
  font-size: 14px;
  color: #ffffff;
}

.text-regular-bold-grey {
  @include font-source-arimo("arimo-bold", 14px, var(--cc-grey-3));
}

.grey-3-14 {
  @include font-source-arimo("arimo-regular", 14px, var(--cc-grey-3));
}

.grey-2-14 {
  @include font-source-arimo("arimo-regular", 14px, var(--cc-grey-2));
}

.table>:not(:first-child) {
  border-top: unset !important;
}

.flex-wrap {
  flex-wrap: wrap
}

.btn-plain-txt {
  @include font-source-arimo('arimo-bold', 14px, var(--cc-blue-btn));
}

.form-check-input:focus {
  box-shadow: unset !important;
}

// .bi-filter-left {
//   border: 2px solid #eceff2;
//   padding: 5px 3px;
//   border-radius: 2px;
// }

.bi-filter-left::before {
  font-weight: 700 !important;
  vertical-align: middle;
  font-size: 24px;

}

.bi-eye::before {
  font-size: 15px;
  font-weight: 700 !important;
  vertical-align: middle;
}

.bi-eye-slash-fill::before {
  font-size: 15px;
  font-weight: 700 !important;
  vertical-align: middle;
}

.gap-17 {
  gap: 17px;
}

.export_filter::before {
  background: url(./assets/images/icons/export_icon1_hover.png) no-repeat;
  content: "";
  background-size: contain;
  display: block;
  width: 17px;
  height: 18px;
  margin-top: 2px;
  float: left;
  color: #3A0CA3;
}

// .export_filter:hover:before {
//   background: url(./assets/images/icons/export_icon1_hover.png) no-repeat;
//   content: "";
//   background-size: contain;
//   display: block;
//   width: 17px;
//   height: 18px;
//   margin-top: 2px;
//   float: left;
// }


.top-filter {
  @include font-source-arimo('arimo-bold', 14px, var(--cc-grey-1));
}

button {
  background: none;
  border: none;
}

.fc .fc-button {
  padding: 0px !important;
}

.cardThead {
  background-color: var(--cc-white);
  border: 1px solid var(--cc-grey-40) !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06) !important;
  border-radius: 8px !important;
}

.form-check-input {
  margin-top: unset;
  vertical-align: middle !important;
}

// ____________________________________________________________________________________________________________________ //

.fs-21 {
  font-size: 21px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-style: 16px !important;
}

// Common Scss
.logo-image {
  width: 30px;
}

.t-d-underline {
  text-decoration: underline !important;
}

.t-dec-none {
  text-decoration: none !important;
}

@media only screen and (max-width: 776px) {
  .logo-image {
    height: 36px;
    width: 60px;
    margin-left: 48px;
    border-left: 1px solid var(--cc-grey-7);
    padding-left: 20px;
  }

  #navbarDropdown {
    span {
      display: none;
    }
  }
}

.align-self-flex-start {
  align-self: flex-start;
}

.align_self_c {
  align-self: center;
}

.divider-right {
  border-right: 1px solid rgba(0, 0, 0, 0.4);
}

.tags {
  flex-wrap: wrap;
  display: flex;

  span {
    margin-right: 6px !important;
  }

  .bg-tags {
    background: var(--cc-grey-6);
    border-radius: 50px !important;
    @include font-source-arimo("arimo-regular", 12px, var(--cc-grey-2));
    padding: 4px 12px;
  }
}

.bg-tags {
  background: var(--cc-grey-6);
  border-radius: 50px !important;
  @include font-source-arimo("arimo-regular", 12px, var(--cc-grey-2));
  padding: 4px 12px;
}

.bg-tags-custom {
  background: var(--cc-grey-6);
  @include font-source-arimo("arimo-regular", 12px, var(--cc-grey-2));
  padding: 4px 8px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.flex-wrap {
  flex-wrap: wrap;
}

// scroll bar

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: var(--cc-grey-7);
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--cc-grey-7);
}

.body {
  .booking-bottom-trey {
    left: 255px !important;
  }

  .booking-bottom-trey-header {
    left: 255px !important;
  }
}

.body-trimmed {
  .booking-bottom-trey {
    left: 82px !important;
    right: 22px !important;
  }

  .booking-bottom-trey-header {
    left: 82px !important;
    right: 22px !important;
  }
}

.body-md-screen {
  .booking-bottom-trey {
    left: 82px !important;
    right: 22px !important;
  }

  .booking-bottom-trey-header {
    left: 82px !important;
    right: 22px !important;
  }
}

.fc-myCustomButtonRight-button {
  background: unset !important;
  border: unset !important;
  color: var(--cc-grey-1) !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  @include font-source-arimo("arimo-medium" !important);
}

.fc-myCustomButtonLeft-button {
  background: unset !important;
  border: unset !important;
  color: var(--cc-grey-1) !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  @include font-source-arimo("arimo-medium" !important);
}

.fc-daygrid-dot-event {
  padding: 0px 0 !important;
}

.fc .fc-toolbar-title {
  font-size: 20px !important;
  color: var(--cc-grey-2) !important;
  margin: 0;
}

.fc-myCustomButtonLeft-button:before {
  content: "\F12C";
  font-family: bootstrap-icons !important;
  color: #333333;
  font-weight: 900;
  font-size: 30px;
  vertical-align: inherit;
}

.fc-myCustomButtonRight-button::after {
  content: "\F135";
  font-family: bootstrap-icons !important;
  color: #333333;
  font-weight: 900;
  font-size: 30px;
  vertical-align: inherit;
}

.fc-prev-button {
  background: unset !important;
  border: unset !important;
  transform: rotate(180deg);
}

.fc-next-button {
  background: unset !important;
  border: unset !important;
}

.profile-picture-l {
  width: 80px;
  height: 80px;
  border-radius: 50px;
}

.place-right {
  place-content: flex-end;
}

@media only screen and (max-width: 768px) {
  .body-md-screen {
    .booking-bottom-trey {
      left: 22px !important;
      right: 22px !important;
    }

    .booking-bottom-trey-header {
      left: 22px !important;
      right: 22px !important;
    }
  }

  .fc-view-harness {
    height: 400px !important;
  }
}

.fc-daygrid {
  .event-indicator {
    overflow: hidden !important;
  }
}

.fc-popover {
  z-index: 999 !important;
}

.headings {
  .profile-picture {
    width: 40px;
    height: 40px;
    border-radius: 50px;
  }
}

.profile-picture {
  width: 48px;
  height: 48px;
  border-radius: 50px;
}

.profile-picture-s {
  width: 32px;
  height: 32px;
  border-radius: 50px;
}

.profile-picture-m {
  width: 48px;
  height: 48px;
  border-radius: 50px;
}

.check-completed {
  color: var(--cc-green-1);
}

.partially-completed {
  color: var(--cc-orange-1);
}

.mandatory-not-completed {
  color: var(--cc-red-2);
}

.v-align-m {
  vertical-align: middle !important;
}

.c-p {
  cursor: pointer;
}

h3 {
  font-weight: 500;
  @include font-source-arimo("arimo-regular", 16px, var(--cc-grey-2));
}

h1 {
  @include font-source-arimo("arimo-bold", 18px, var(--cc-grey-1));
}

.u_n {
  @include font-source-arimo("arimo-bold", 14px, var(--cc-grey-1));
}

.cal_pp {
  width: 32px;
  height: 32px;
  border-radius: 50px;
}

// Ngx Ui Loader
.ngx-loading-text {
  @include font-source-arimo("arimo-regular", 14px, var(--cc-white));
}

// Common Colors
.clr-green {
  color: var(--cc-green-5) !important;
}

.clr-red {
  color: var(--cc-red-2) !important;
}

.clr-orange {
  color: var(--text-orange) !important;
}

.clr-pink {
  color: var(--cc-pink-1) !important;
}

.clr-grey-7 {
  color: var(--cc-grey-7) !important;
}

.clr-grey-3 {
  color: var(--cc-grey-3) !important;
}

.clr-white {
  color: var(--cc-white) !important;
}

// .theme-green .bs-datepicker-head {
//     background-color: #ffffff !important;
// }

// .bs-datepicker-head button {
//     display: inline-block;
//     vertical-align: top;
//     padding: 0;
//     height: 30px;
//     line-height: 30px;
//     border: 0;
//     background: #ededed !important;
//     color: #000000 !important;
// }

.bs-datepicker-head button.current {
  border-radius: 15px;
  max-width: 155px;
  padding: 0 13px;
  @include font-source-arimo("arimo-medium", 13px);
}

// Ngx datepicker customize
.bs-datepicker-container {
  padding: unset !important;
}

.theme-classcover {
  background: #0c0c60 !important;
}

.theme-red .bs-datepicker-head {
  background: #ffffff !important;
}

.theme-red .bs-datepicker-body table td span.selected,
.theme-red .bs-datepicker-body table td.selected span,
.theme-red .bs-datepicker-body table td span[class*="select-"]:after,
.theme-red .bs-datepicker-body table td[class*="select-"] span:after {
  background: rgba(58, 12, 163, 1) !important;
}

.theme-orange .bs-datepicker-body table td span.selected,
.theme-orange .bs-datepicker-body table td.selected span,
.theme-orange .bs-datepicker-body table td span[class*="select-"]:after,
.theme-orange .bs-datepicker-body table td[class*="select-"] span:after {
  background: rgba(58, 12, 163, 1) !important;
}

.bs-datepicker-head {
  background-color: #ffffff !important;
}

.theme-orange.bs-datepicker {
  border-radius: 8px !important;
  box-shadow: unset !important;
  top: 0px !important;
}

.theme-dark-blue.bs-datepicker {
  border-radius: 8px !important;
  box-shadow: unset !important;
  top: 0px !important;
}

.theme-dark-blue .bs-datepicker-body table td span.selected,
.theme-dark-blue .bs-datepicker-body table td.selected span,
.theme-dark-blue .bs-datepicker-body table td span[class*=select-]:after,
.theme-dark-blue .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #ffffff !important;
  color: #4F4F4F;
}

.theme-dark-blue .bs-datepicker-body table td span.date-bg,
.theme-dark-blue .bs-datepicker-body table td.date-bg span,
.theme-dark-blue .bs-datepicker-body table td span[class*=select-]:after,
.theme-dark-blue .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #3a0ca3 !important;
  color: #ffffff !important;
}

.theme-dark-blue .bs-datepicker-body {
  min-height: 210px !important;
}

.theme-orange .bs-datepicker-body {
  min-height: 210px !important;
}

.theme-default .bs-datepicker-body {
  min-height: 210px !important;
}

.bs-datepicker-body table td span {
  font-size: 12px !important;
}

.bs-datepicker-body table.days span {
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
}

.bs-datepicker-body table td.week span {
  color: #0c0c60 !important;
}

.theme-red.bs-datepicker {
  border-radius: 5px !important;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1) !important;
  top: 5px !important;
}



.jobs_only_user {

  .theme-red .bs-datepicker-body table td span.selected,
  .theme-red .bs-datepicker-body table td.selected span,
  .theme-red .bs-datepicker-body table td span[class*="select-"]:after,
  .theme-red .bs-datepicker-body table td[class*="select-"] span:after {
    background: #f92b8b !important;
  }

  .theme-red .bs-datepicker-body table td.week span {
    color: #f92b8b !important;
  }
}

.bs-datepicker-body table td span.disabled,
.bs-datepicker-body table td.disabled span {
  color: #dedede !important;
}

.bs-datepicker-body table th {
  color: #333333 !important;
  font-size: 12px !important;
}

.bs-datepicker-body {
  min-width: 240px !important;
  padding: 0px 15px !important;
  border: unset !important;

  tr.ng-star-inserted {
    height: 32px;
  }
}

.theme-green {
  .bs-datepicker-head {
    background-color: #ffffff !important;
  }

  .bs-datepicker-head button {
    color: #333333 !important;
  }

}

.theme-red {
  .bs-datepicker-head button.current.ng-star-inserted {
    border-radius: 15px;
    max-width: 155px;
    padding: 0 13px;
    position: absolute !important;
    left: 0 !important;
    margin-left: 6px !important;
  }
}

.bs-datepicker-head button.previous {
  width: 30px;
  height: 30px;
  /* display: inline-table; */
  /* float: right; */
  position: absolute !important;
  right: 40px !important;
}

.bs-datepicker-head {
  min-width: 240px !important;
}

.bs-datepicker-body table td {
  color: #4F4F4F !important;
  font-size: 12px !important;
  text-align: center;
  position: relative;
  padding: 0;
}

.bs-datepicker-head button {
  color: #333333 !important;
}

// Popover css booking request page
.popover {
  max-width: 170px !important;
  top: -5px !important;
  @include font-source-arimo("arimo-regular", 14px, var(--cc-grey-3));
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px !important;
  border: unset;
  z-index: 0 !important;
}

.popover-body {
  padding: 8px !important;
  color: var(--cc-grey-3) !important;
}

.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::before,
.bs-popover-top>.popover-arrow::before {
  border-top-color: rgba(0, 0, 0, 0) !important;
}

.ng-select.ng-select-opened .ng-select-container {
  z-index: 0 !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  z-index: 0 !important;
}

.ng-dropdown-panel {
  z-index: 10 !important;
  -webkit-overflow-scrolling: touch;
}

// .ng-arrow-wrapper:after{
//     content: " - Remember this";
// }
.ng-arrow {
  top: -2px;
  box-sizing: border-box !important;
  height: 6px !important;
  width: 6px !important;
  border-style: solid !important;
  border-color: #828282 !important;
  border-width: 0px 1.6px 1.6px 0px !important;
  transform: rotate(45deg);
  transition: border-width 150ms ease-in-out;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: unset;
  padding-bottom: 5px;
  padding-left: 3px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-weight: 500 !important;
  // color: var(--cc-grey-7) !important;
}
.default-theme.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked{
  background-color: #f5faff;
    color: #333;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--cc-blue-1);
  font-size: 20px;
  background-color: transparent !important;
  border-bottom: 2px solid var(--cc-blue-1);
  border-color: #ffffff00 #ffffff00 #ffffff00;
}
.profilePopUpTab .nav-item.show .nav-link,
.profilePopUpTab .nav-link.active {
  color: var(--cc-blue-1);
  font-size: 16px;
  background-color: transparent !important;
  border-bottom: 2px solid var(--cc-blue-1);
  border-color: #ffffff00 #ffffff00 #ffffff00;
}

.nav-link.active {
  color: var(--cc-blue-1);
  border-bottom: 2px solid var(--cc-blue-1) !important;
  font-weight: 600;
}
.width-40{
width: 40% !important;
}
.invite-request-preview {
  .nav-link.active {
    color: var(--cc-grey-1);
    font-weight: 600 !important;
    border-bottom: 2px solid var(--cc-blue-btn) !important;
  }

  .nav-link {
    color: var(--cc-grey-3);
    font-weight: 500 !important;
  }
}

.nav-tabs {
  border-bottom: none;

  .nav-link {
    display: block;
    font-size: 20px;
    padding: 0px 0px 5px 0px;
    margin-right: 25px;
    color: var(--cc-grey-2);
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }
}
.profilePopUpTab {
  border-bottom: none;

  .nav-link {
    display: block;
    font-size: 16px;
    padding: 0px 0px 5px 0px;
    margin-right: 25px;
    color: var(--cc-grey-2);
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
  isolation: unset;
}

a {
  text-decoration: none !important;
}

.fit-content {
  width: fit-content;
}

h4 {
  font-family: "arimo-medium" !important;
  font-size: 20px;
  color: var(--cc-grey-1);
  font-weight: 500;
}

.line-item {
  padding: 3px 0px;

  img {
    margin-right: 10px;
  }

  p {
    font-size: 12px;
    color: #707070;

    .bi-x {
      font-size: 20px;
      font-weight: 600;
    }
  }

  .name {
    font-size: 14px;
    color: #333333;
  }
}
.hideON991{
  display: block;
}
@media (max-width: 991px) {
  .modal-lg {
    max-width: 800px;
    margin: 1.75rem auto;
  }
  .hideON991{
    display: none;
  }
}

@media (max-width: 881px) {
  .modal-lg {
    margin: 1.75rem 30px;
  }
}

.border-t {
  border-top: 1px solid #c9c9c9;
}

.border-b {
  border-bottom: 1px solid #c9c9c9;
}

.modal-content {
  padding: 15px 15px;
}

.modal-header {
  padding: 0px;
  border-bottom: none;
}

.modal-body {
  padding: 10px 0px;
}

.modal-title {
  @include font-source-arimo("arimo-medium", 18px, var(--cc-grey-1));
  line-height: 21px;
}


.modal-footer {
  padding: 7px 0px 0px 0px;
  border-top: none;
}

.form-check-input[type="radio"] {
  border-radius: 50% !important;
}

.btn_cc_back {
  background: none;
  border: none;
  @include font-source-arimo("arimo-bold", 13px, var(--cc-grey-2));
}

.btn {
  padding: 0px;
}

.show-div {
  display: block;
}

.hide-div {
  display: none;
}

.pb-90 {
  padding-bottom: 90px;
}

.pt-90 {
  padding-top: 90px;
}

// ____________________________________________________________________________________________________________________ //

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

// School Calendar Events
.fc-daygrid-event {
  margin-right: 0px !important;
  margin-left: 0px !important;
  border-radius: 0px !important;
}

.fc-h-event {
  background-color: transparent !important;
  border: 0px !important;
}

.status-booked {
  background-color: var(--cc-blue-4) !important;
  border-left: 2px solid var(--cc-blue-5) !important;
  padding: 2px !important;
  color: var(--cc-grey-2);
  font-size: 10px;

  p {
    margin-bottom: 0px !important;
  }
}

.status-external-event {
  background-color: var(--cc-purple-1) !important;
  border-left: 2px solid var(--cc-purple-1) !important;
  padding: 2px !important;
  color: var(--cc-white);
  font-size: 10px;

  p {
    margin-bottom: 0px !important;
  }
}

.fc-scroller-harness {
  .fc-col-header-cell-cushion {
    @include font-source-arimo("arimo-semibold", 14px, var(--cc-grey-1));
  }
}

.fc-dayGridWeek-view {
  .status-booked {
    .event-time {
      color: #0076c5;
      font-weight: 500;
      @include font-source-arimo("arimo-semibold");
    }
  }
}

.event-time {
  color: var(--cc-grey-2);
  font-weight: 500;
  @include font-source-arimo("arimo-semibold");
}

.event-name {
  color: var(--cc-grey-2);
  font-weight: 500;
  @include font-source-arimo("arimo-semibold");
}
// .event-classHead {
//   color: var(--cc-grey-3);
//   font-weight: 500;
//   font-family: "arimo-semibold";
// }
.event-classDis {
  color: var(--cc-grey-3);
}

.status-external-event {
  .event-time {
    color: #ffffff;
    font-weight: 500;
    @include font-source-arimo("arimo-semibold");
  }

  .event-name {
    color: #ffffff;
    font-weight: 500;
    @include font-source-arimo("arimo-semibold");
  }
}

.fc-scrollgrid-sync-table {
  .fc-daygrid-day-number {
    @include font-source-arimo("arimo-semibold", 16px, var(--cc--black));
  }
}

.fc-toolbar-chunk {

  .fc-previousMonth-button,
  .fc-nextMonth-button {
    padding: 0px !important;
    background-color: transparent !important;
    border: 0 !important;
    @include font-source-arimo("arimo-bold", 18px, var(--cc-grey-1));
  }

  .fc-toolbar-title {
    @include font-source-arimo("arimo-bold", 20px, var(--cc-grey-2));
  }
}

.show-errors {
  @include font-source-arimo("arimo-medium", 13px, var(--cc-red-6));
}

.page-item.active .page-link {
  background-color: #3A0CA3;
  border-color: #3A0CA3;
  color: #ffffff !important;
  font-size: 14px;
}

.page-link {
  @include font-source-arimo("arimo-medium", 14px, var(--cc--black));
  color: #3A0CA3;
}

.page-item.disabled .page-link {
  color: #90979d !important;
  pointer-events: none;
  background-color: #fff !important;
  border-color: #dee2e6 !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #1e2125;
  text-decoration: none;
  background-color: #e9ecef;
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
  opacity: 1;
}

.select-filter-list {
  .dropdown-toggle::after {
    // display: none;
    margin-left: 22px;
    vertical-align: super;
  }

  .form-check-input {
    width: 19px !important;
    height: 19px !important;
    background-color: var(--cc-grey-9) !important;
    border: 1px solid var(--cc-grey-1) !important;
    border-radius: 3px !important;
  }
}

.justify-content-right {
  justify-content: right;
}
.jusC-C {
  justify-content: center;
}

.input-cursor-na {
  cursor: not-allowed !important;
  pointer-events: none;
  opacity: 0.5;
}

.ng-option.ng-option-disabled {
  font-family: "arimo-medium" !important;
  background-color: #f1ecff !important;
  color: var(--cc--black) !important;
}

.ng-option-disabled.ng-optgroup {
  font-family: "arimo-medium" !important;
  background-color: #f1ecff !important;
  color: var(--cc--black) !important;
}

.dropdown-item {
  padding: 8px !important;
}

.dropdown-menu {
  padding: 10px !important;
  font-size: 14px !important;
}

.flex-warp {
  flex-wrap: wrap;
}

label {
  @include font-source-arimo("arimo-medium", 13px, var(--cc--black));
  margin-bottom: 3px;
}

.no-result-image {
  width: 40%;
}

.text-right {
  text-align: right;
}

.ng-select .ng-clear-wrapper {
  display: none;
}

.ng-select .ng-option-selected,
.ng-select .ng-option:hover {
  background-color: #f2f2f2 !important;
  font-weight: 400 !important;
  border-radius: 4px;
}

.ng-select .ng-option-disabled:hover {
  font-family: "arimo-medium" !important;
  background-color: #f1ecff !important;
  color: var(--cc--black) !important;
  border-radius: 4px;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  padding: 10px;
  max-height: 225px !important;
}

.booking-request-page-demo{
  .ng-select-disabled>.ng-select-container {
    background-color: #ffffff !important;
  
    .ng-select .ng-value {
      background-color: #ffffff !important;
    }
  } 
  .ng-select .ng-select-container:hover {
    background-color: #ffffff !important;
  }
}
.ng-select-disabled>.ng-select-container {
  background-color: #f2f2f2 !important;

  .ng-select .ng-value {
    background-color: #f2f2f2 !important;
  }
}

.n_df {
  height: 250px;
  margin: 10px 10px;
}

.s_n {
  @include font-source-arimo("arimo-bold");
  color: var(--cc-grey-1);
  font-size: 16px;
  cursor: pointer;
}

.s_n:hover {
  text-decoration: underline !important;
  color: var(--cc-blue-btn) !important;
}

.form-control:hover,
.form-select:hover {
  background-color: var(--cc-grey-6) !important;
}

.ng-select .ng-select-container:hover {
  background-color: var(--cc-grey-6) !important;
}

.ng-select .ng-select-container:focus {
  background-color: var(--cc-grey-6) !important;
}

.status-Cancelled {
  background-color: var(--cc-red-cal) !important;
  border-left: 2px solid var(--cc-red-6) !important;
  padding: 2px !important;
  color: var(--cc-grey-2);
  font-size: 10px;

  p {
    margin-bottom: 0px !important;
  }
}

.clr-grey {
  color: var(--cc-grey-2);
}

.b {
  font-weight: 600;
}

.fc-day-sat {
  width: 50px;
}

.fc-day-sun {
  width: 50px;
}

// .fc .fc-scroller-liquid-absolute {
//     position: relative !important;
// }
.fc-view-harness {
  height: 697px !important;
}

.fc-col-header {
  width: 100% !important;
}

.fc-scrollgrid-sync-table {
  width: 100% !important;
}

.fc-daygrid-body {
  width: 100% !important;
}

.add-image {
  .filelabel {
    border: none;
    background-color: var(--cc-grey-6);
    border-radius: 5px;
    display: block;
    padding: 5px;
    height: 156px;
    transition: border 300ms ease;
    cursor: pointer;
    text-align: center;
    margin: 0;
  }

  .file-upload-text {
    padding: 3px 50px;
    border: 1px solid var(--cc-grey-3);
    border-radius: 8px;
    color: var(--cc-grey-2) !important;
  }

  .bi-paperclip {
    transform: rotate(90deg);
    font-size: 20px;
    color: var(--cc-grey-3);
  }

  .bi-paperclip::before {
    font-weight: 600 !important;
  }

  input[type="file"]::-webkit-file-upload-button {
    visibility: hidden;
  }

  #FileInput {
    // margin-top: -151px;
    // height: 155px;
    opacity: 0;
  }
}

.carousel-control-next,
.carousel-control-prev {
  width: 4%;
  opacity: 1;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  background: var(--cc-blue);
  border-radius: 50%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.max_c {
  width: max-content;
}

.carousel-inner {
  position: relative;
  width: 100%;
  margin-right: 30px;
  margin-left: 30px;
  overflow: hidden;
}

.invite-request-preview {

  .nav-link,
  .nav-link.active {
    font-size: 16px !important;
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  font-size: 0.9em;
  display: flex;
  margin-bottom: 5px;
  color: #333333;
  background-color: #f2f2f2;
  border-radius: 50px;
  margin-right: 5px;
  padding: 2px 9px 2px 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
  border-left: unset !important;
  font-size: 19px;
  margin-left: 0px;
  top: 4px;
  color: #828282;
  margin-top: -4px;
  height: 0px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  display: inline-block;
  padding: 0px 0px;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: var(--cc-red-6) !important;
}

.boost_jobs_card {
  background: #ffeaf4 !important;

  .boost_img {
    height: 135px;
    width: 100px;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }
}

.s_between {
  justify-content: space-between;
}

.jus_c_right {
  justify-content: flex-end;
}
.flex-wrap{
  flex-wrap: wrap;
}

.s_even {
  justify-content: space-evenly;
}

.s_around {
  justify-content: space-around;
}

.grey-3 {
  color: var(--cc-grey-3) !important;
}

.ngx-ic-cropper {
  color: #333333;
  border-radius: 50% !important;
  outline: auto !important;
}

.dropdown-item:hover {
  border-radius: 4px !important;
}

.booking_user {
  .job-boosted {
    background: var(--cc-green-badge-b);
    padding: 3px 8px;
    color: var(--cc-green-badge) !important;
    border-radius: 50px;
    width: fit-content;
    text-wrap: nowrap;
    height: max-content;
    align-items: center;
  }

  .o_t {
    font-size: 29px !important;
    font-weight: 600;
    color: var(--cc-blue) !important;
  }

  .u_t_r16 {
    @include font-source-poppins("poppins_r");
    font-size: 16px;
    color: var(--cc-blue);
  }
}

.fs-12 {
  font-size: 12px;
}

.page_title {
  font-size: 28px;
  color: var(--cc-grey-1);
  font-weight: 600;
}


.jobs_only_user {
  @include font-source-poppins("poppins_r");

  .modal-title {
    @include font-source-poppins("poppins_m");
    font-size: 18px;
    line-height: 21px;
    color: #333333;
  }

  .form-control,
  .form-select {
    @include font-source-poppins("poppins_r");
  }

  // Switches/Toggles
  /* Rounded sliders */
  .switch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 22px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--cc-grey-7);
      -webkit-transition: 0.4s;
      transition: 0.4s;

      &:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 15.51px;
        left: 4px;
        top: 2.5px;
        background-color: var(--cc-white);
        transition: 0.4s;
      }
    }

    input {
      &:checked {
        + {
          .slider {
            background-color: var(--cc-pink-1);

            &:before {
              -webkit-transform: translateX(18px);
              -ms-transform: translateX(18px);
              transform: translateX(18px);
            }
          }
        }
      }

      &:focus {
        + {
          .slider {
            box-shadow: 0 0 1px #2196f3;
          }
        }
      }
    }

    .slider.round {
      border-radius: 34px;

      &:before {
        border-radius: 50%;
      }
    }
  }

  .btn_cc_back {
    @include font-source-poppins("poppins_m");
    font-weight: 600;
  }

  .cc_p_btn {
    @include font-source-poppins("poppins_r");
    font-weight: 600;
  }

  .show-errors {
    @include font-source-poppins("poppins_r");
    font-weight: 500;
  }

  .text-regular-medium {
    @include font-source-poppins("poppins_m");
    font-size: 14px;
    font-weight: 600;
    color: var(--cc-grey-1);
  }

  .ng-select .ng-select-container .ng-value-container {
    @include font-source-poppins("poppins_r");
  }

  .ng-select .ng-option {
    @include font-source-poppins("poppins_r");
  }

  .ng-input>input {
    @include font-source-poppins("poppins_r");
  }

  .ng-placeholder {
    @include font-source-poppins("poppins_r");
    color: var(--cc-grey-2) !important;
  }

  ::-webkit-input-placeholder {
    @include font-source-poppins("poppins_r");
    color: var(--cc-grey-2) !important;
  }

  ::placeholder {
    @include font-source-poppins("poppins_r");
    color: var(--cc-grey-2) !important;
  }

  .text_medium {
    @include font-source-poppins("poppins_sb");
    font-size: 14px;
    color: var(--cc-grey-1);
  }

  .ul-title {
    @include font-source-poppins("poppins_m");
    font-size: 17px;
    font-weight: 600;
    color: var(--cc-black);
  }

  .text-regular-medium {
    @include font-source-poppins("poppins_m");
    font-size: 14px;
  }

  .text-regular-medium-grey {
    @include font-source-poppins("poppins_m");
    font-size: 14px;
    font-weight: 600;
    color: #828282;
  }

  // Heading & Para
  .user-title {
    @include font-source-poppins("poppins_sb");
    font-size: 16px;
    font-weight: 600;
    word-break: break-word;
    margin-right: 10px
  }

  // Heading & Para
  .cta-sb {
    @include font-source-poppins("poppins_sb");
    font-size: 14px;
    font-weight: 600;
  }

  .txt-r-sb {
    @include font-source-poppins("poppins_sb");
    font-size: 14px;
  }

  .sm-tx-sb {
    @include font-source-poppins("poppins_sb");
    font-size: 12px;
    color: var(--cc-grey-1);
  }

  .user-sub-title {
    @include font-source-poppins("poppins_r");
    font-size: 12px;
    color: var(--cc-grey-1);
  }

  .tags .bg-tags {
    @include font-source-poppins("poppins_r");
  }

  .text-regular-bold {
    @include font-source-poppins("poppins_b");
    font-size: 14px;
    font-weight: 600;
  }

  .text-16-bold {
    @include font-source-poppins("poppins_b");
    font-size: 16px;
    font-weight: 600;
  }

  .grey-3-14 {
    @include font-source-poppins("poppins_r");
  }



  .page_title {
    @include font-source-poppins("poppins_sb");
    font-size: 32px;
    color: var(--cc-grey-1);
    font-weight: 600;
  }

  .txt-regular {
    @include font-source-poppins("poppins_r");
    font-weight: 400;
    font-size: 14px;
  }

  .txt-regular-16 {
    @include font-source-arimo("arimo-regular", 16px);
    color: var(--cc-grey-1);
  }

  .text-s-m-g-3 {
    @include font-source-poppins("poppins_r");
    font-size: 12px;
    font-weight: 500;
  }

  .text-s-m-g-2 {
    @include font-source-poppins("poppins_m");
    font-size: 12px;
    font-weight: 500;
  }

  .text-s-r-g-2 {
    @include font-source-poppins("poppins_r");
    font-size: 12px;
    font-weight: 500;
  }

  .text-14-g1-m {
    @include font-source-poppins("poppins_m");
    font-size: 14px;
    font-weight: 500;
  }

  .text-14-g2-r {
    @include font-source-poppins("poppins_r");
    font-size: 14px;
    font-weight: 400;
  }

  .text-s-m-g-1 {
    @include font-source-poppins("poppins_m");
    font-size: 12px;
    font-weight: 500;
    color: var(--cc-grey-1);
  }

  .txt_r_m_g {
    @include font-source-poppins("poppins_m");
    font-size: 16px;
    font-weight: 500;
    color: var(--cc-grey-1);
  }

  .ver_a_sub {
    vertical-align: sub;
  }

  h4 {
    @include font-source-poppins("poppins_sb");
    font-size: 20px;
    color: var(--cc-grey-1);
    font-weight: 500;
  }

  .job-title {
    @include font-source-poppins("poppins_r");
    font-size: 17px;
    font-weight: 600;
  }

  .boost_heading {
    @include font-source-poppins("poppins_m");
    font-size: 20px;
    font-weight: 600;
  }

  .h_b:hover {
    color: #f92b8b !important;
  }

  .h_b_ul:hover {
    color: #f92b8b !important;
    text-decoration: underline;
  }

  .s_n:hover {
    text-decoration: underline !important;
    color: #f92b8b !important;
  }

  .no_appli_found {
    @include font-source-poppins("poppins_r");
    font-size: 17px;
    font-weight: 400;
  }

  .txt_r_g {
    @include font-source-poppins("poppins_r");
    font-size: 14px;
    color: var(--cc-grey-2);
  }

  .head_des {
    font-size: 17px;
    font-weight: 400;
  }

  .u_r_s_g2 {
    @include font-source-poppins("poppins_r");
    font-size: 12px;
    color: var(--cc-grey-2);
  }

  .u_r_g2 {
    @include font-source-poppins("poppins_r");
    font-size: 14px;
    color: var(--cc-grey-2);
  }

  .u_t_r16 {
    @include font-source-poppins("poppins_r");
    font-size: 16px;
    color: var(--cc-grey-1);
  }

  .text-regular-bold-w {
    @include font-source-poppins("poppins_r");
    font-size: 14px;
    color: #ffffff;
  }

  .clr-cc-vilot {
    color: #f92b8b !important;
  }

  .btn_jobs_sec {
    border: 1px solid #f92b8b;
    background: #ffffff;
    color: #f92b8b !important;
    border-radius: 8px;
    padding: 7px 40px !important;
    height: 40px;
    cursor: pointer;
    font-family: "poppins_r" !important;
    font-weight: 600;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    @include font-source-poppins("poppins_sb");
  }

  .nav-tabs .nav-link {
    @include font-source-poppins("poppins_m");
  }

  .o_t {
    font-size: 29px !important;
    font-weight: 600;
    color: #f92b8b !important;
  }

  // .nav-tabs .nav-link.active {
  //     color: #F92B8B !important;
  // }

  .type-badge {
    color: #f92b8b !important;
  }

  .job-boosted {
    background: #e2deff;
    padding: 3px 8px;
    color: #070166 !important;
    @include font-source-poppins("poppins_sb");
    font-size: 12px;
    border-radius: 4px;
    width: fit-content;
    text-wrap: nowrap;
    height: max-content;
    align-items: center;
  }

  .cc_p_btn {
    background: #f92b8b !important;
  }

  .cc_p_btn_outline {
    border: 1px solid #f92b8b !important;
    color: #f92b8b !important;
    font-family: "poppins_r" !important;
    font-weight: 600;
  }

  .form-check-input:checked {
    background-color: #f92b8b !important;
    border: 1px solid #f92b8b !important;
  }

  .form-switch .form-check-input:checked {
    height: 20px !important;
    background-color: #100460 !important;
    border: 1px solid #100460 !important;
    cursor: pointer;
  }

  .form-check-label {
    @include font-source-poppins("poppins_r");
    font-size: 14px !important;
    color: var(--cc-grey-2) !important;
    margin-top: 4px;
  }

  label {
    @include font-source-poppins("poppins_m");
  }

  .sidenav-nav-item {
    .sidenav-nav-link {
      @include font-source-poppins("poppins_r");
    }
  }
}

.subs_head {
  font-size: 18px;
  color: #100460;
  margin-bottom: 15px;
  @include font-source-poppins("poppins_m");
  font-weight: 600;
}

.active-submenu {
  font-weight: 600 !important;
  color: var(--cc-grey-1) !important;
}

.job_subs_page {
  ul {
    padding: 0px;
    list-style-type: none !important;

    li {
      list-style-type: none !important;
      margin-top: 10px;
      display: flex;
      @include font-source-poppins("poppins_r");
      color: var(--cc-grey-1);
    }

    .bi-check2 {
      margin-right: 10px;
    }

    .bi::before,
    [class^="bi-"]::before,
    [class*=" bi-"]::before {
      font-weight: 700 !important;
    }
  }
}

.sign_txt {
  @include font-source-poppins("poppins_r");
  color: #fff;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  margin-top: 30px;
}

.join_hub {
  color: #fff;
  font-size: 40px;
  @include font-source-poppins("poppins_b");
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 30px;
}

.display-profile-header {

  // place-content: flex-end;
  .dropdown {
    span {
      font-size: 14px;
      color: #ffffff !important;
      @include font-source-poppins("poppins_r");
    }
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-menu {
    top: 50px;
    left: unset;
    margin-top: 0;
    right: 0;

    li {
      cursor: pointer;

      a {
        font-size: 14px;
        @include font-source-poppins("poppins_r");
      }
    }
  }
}

.plan-badge {
  font-family: "arimo-bold" !important;
  // @include font-source-poppins('poppins_b');
  margin-bottom: 0px;
  padding: 0px 4px;
  margin-right: 10px;
  color: #03935d;
  font-weight: 700;
  font-size: 12px;
  background-color: #e8faf0;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
}

.plan-badge:hover {
  color: #03935d;
}

.btn_jobs_sec {
  border: 1px solid #070166;
  background: #ffffff;
  color: #070166;
  border-radius: 8px;
  font-family: "arimo-bold" !important;
  padding: 7px 40px !important;
  height: 40px;
  cursor: pointer;
}

.fs-30 {
  font-size: 30px !important;
}

.ProfileModaldisabled {
  pointer-events: none;
}

@media only screen and (max-width: 529px) {
  .nav-tabs .nav-link {
    margin-top: 10px;
  }
}

.delay-send-time-picker {

  .ng-dropdown-panel-items,
  .ng-dropdown-panel {
    width: fit-content !important;
  }
}

.delay-send-time-picker,
.delay-send-time-picker:hover {
  background-color: transparent !important;
  border: none !important;
}

.delay-send-time-picker .ng-value {
  color: #070166 !important;
}

.delay-send-time-picker .ng-arrow {
  border-color: #070166 !important;
}

.txt-webkit-cntr {
  text-align: -webkit-center !important;
}

.sortByBR .dropdown-toggle::after {
  display: none !important;
}

.calenderView {
  .ng-select-container {
    border: unset !important;
    border-radius: unset !important;
    color: var(--cc-grey-3) !important;
    min-height: 37px;
    background-color: #fafafa !important;
  }

  .ng-select-container:hover {
    background-color: unset !important;
    box-shadow: unset !important;
  }

  .ng-dropdown-panel {
    width: fit-content;
  }

  .ng-select-container .ng-value-container {
    font-family: "arimo-bold";
    font-size: 14px;
    color: var(--cc-grey-1);
  }

  .ng-arrow {
    height: 7px !important;
    width: 7px !important;
    border-color: #000000 !important;
    border-width: 0px 2px 2px 0px !important;
  }

  .ng-input {
    cursor: pointer !important;
  }
}

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.customDatepickerExport {
  .btn.btn-default:hover {
    color: unset !important;
  }

  .btn.btn-link:hover {
    color: unset !important;
  }
}

@media only screen and (max-width: 768px) {
  .side_c {
    display: none;
  }

  .mblResTop {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .mblResTop {
    display: none;
  }
}

.word-break {
  word-break: break-word;
}

.cc_p_btn_outline {
  background: #ffffff;
  border: 1px solid var(--cc-blue-btn) !important;
  border-radius: 8px;
  font-family: "arimo-bold";
  color: var(--cc-blue-btn) !important;
  padding: 7px 20px !important;
  height: 40px;
  padding: 10px 10px;
  cursor: pointer;
}
.cc_p_s_btn_outline {
  background: #ffffff;
  border: 1px solid var(--cc-green-5) !important;
  border-radius: 8px;
  font-family: "arimo-bold";
  color: var(--cc-green-5) !important;
  height: 40px;
  padding: 10px 10px;
  cursor: pointer;
  width: 136px;
}

.new_tag  {
  font-size: 12px;
  margin-top: -15px;
  padding: 0px 4px;
  border-radius: 4px;
  background: var(--cc-green-7);
  font-family: "arimo-bold";
  color: var(--cc-green-8);
}
.daily-summary {
  .ng-dropdown-panel {
    width: fit-content !important;
  }
}

.u_l {
  text-decoration: underline !important;
}

p.hvr_btn:hover {
  background: #F9F1FE;
  // padding: 3px 6px;
  border-radius: 4px;
}

.placeContentCenter {
  place-content: center;
}

.ck-content {
  height: 200px;
}

.ck.ck-list__item>.ck-heading_heading2 {
  display: none;
}

.ck.ck-list__item>.ck-heading_heading1 {
  display: none;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
  z-index: 1;
}

// .intercom-lightweight-app-launcher{
//   bottom: 80px ;
// }

.boost_jobs_cards {
  background: #ffffff !important;
  border: 1px solid #F92B8B;
}

.free_boostNotify {
  background-color: #F2F2F2;
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 4px;
  height: 47px;
  place-self: flex-start;
  border-top-right-radius: 8px;
  line-height: 1;
}

.int_off {
  background-color: #FFEAF4;
  width: fit-content;
  padding: 2px;
}

.strike_rate {
  text-decoration: line-through;
}
.lh-12{
  line-height: 1.2 !important;
}
.notesTooltip {
  position: relative;
  display: inline-block;
}

.notesTooltip .notesTooltiptext{
  visibility: hidden;
  padding: 6px 8px;
  font-size: 12px;
  background-color: black;
  color: #ffffff;
  /* width: 127px; */
  text-align: center;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  width: -webkit-fill-available;
  bottom: 100%;
  // flex-wrap: wrap;
  // display: flex;
  left: 50%;
  /* width: fit-content; */
  margin-left: -60px;
}

.notesTooltip:hover .notesTooltiptext {
  visibility: visible;
}
.notesTooltip .notesTooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000000 transparent transparent transparent;
}
.btn-outline{
  border: 1px solid;
  padding: 6px 16px;
  border-radius: 6px;
}
.cb_label{
  @include font-source-arimo("arimo-medium", 14px, var(--cc-blue-btn));
}

.accordion-button:focus-visible {
  outline: -webkit-focus-ring-color auto 2px;
  border-radius: 5px;
}
input[type=checkbox]:focus{
  outline: -webkit-focus-ring-color auto 2px;
}

input[type=text]:focus-visible {
  outline: -webkit-focus-ring-color auto 2px;
}
input[type=select]:focus-visible {
  outline: -webkit-focus-ring-color auto 2px;
}
input[type=image]:focus-visible {
  outline: -webkit-focus-ring-color auto 2px;
}
textarea:focus {
  outline: -webkit-focus-ring-color auto 2px !important;
}
.FileUpload1:focus{
  .filelabel{
    outline: -webkit-focus-ring-color auto 2px !important;
  }
}
.ng-select-focused {
  outline: -webkit-focus-ring-color auto 2px;
  border-radius: 10px !important;
}
.ng-select-opened {
  outline: -webkit-focus-ring-color auto 2px;
  border-radius: 10px !important;
}

.angular-editor-textarea{
  background-color: #ffffff !important;
}
.angular-editor-toolbar{
  background-color: #ffffff !important;
  padding-top: 5px !important;
align-items:  center !important;
padding-bottom: 10px !important;
}
.angular-editor-button{
  border: none !important;
}
.ae-picker-options{
  button:nth-child(1){
    display: none !important;
  }
  // button:nth-child(2){
  //   display: none !important;
  // }
  // button:nth-child(3){
  //   display: none !important;
  // }
  button:nth-child(5){
    display: none !important;
  }
  button:nth-child(6){
    display: none !important;
  }
  button:nth-child(7){
    display: none !important;
  }
  button:nth-child(9){
    display: none !important;
  }
  button:nth-child(10){
    display: none !important;
  }
  button:nth-child(11){
    display: none !important;
  }
}

.ae-picker-options .ae-picker-item.focused{
  background-color:#f0f7ff !important;
  color:#2977ff !important;
}
.ae-picker-options .ae-picker-item:hover{
  background-color:#f0f7ff !important;
  color:#2977ff !important;
}
.angular-editor-button:hover {
  cursor: pointer;
  background-color:#f0f7ff !important;
  color:#2977ff !important;
  transition: .2s ease;
}
.select-heading{
width: 120px !important;
font-size: 14px !important;
}
.ae-picker-options .ae-picker-item{
  padding: 8px !important;
  min-width: 120px !important;
  font-size: 14px !important;
}
.ae-picker-label svg:not(:root){
  display: none !important;
}
.ae-picker-label{
  font-size: 100% !important;
  height: 35px !important;
}
.angular-editor .angular-editor-wrapper{
  margin-top: -10px !important;
}
.angular-editor-button.active{
  background-color:#f0f7ff !important;
  color:#2977ff !important;
}
.angular-editor-textarea p{
  b{
    font-family: "arimo-bold" !important;
  }
}
.angular-editor-textarea b{
    font-family: "arimo-bold" !important;
}

.angular-editor-button {
  background-color: #fff;
  vertical-align: middle;
  border: 1px solid #ddd;
  padding: .4rem;
  font-size: 21px !important;
  min-width: 2rem;
  float: left;

  .bi-link-45deg::before {
    font-weight: 500 !important;
}
}
.angular-editor-textarea{
  h3{
    font-size: 1.75rem !important;
  }
}
 .angular-editor-button:disabled {
  background-color: #f5f5f5;
  pointer-events: none;
  cursor: all-scroll;
  color: #333333 !important;
}

.d-grid{
  display: grid !important;
}
.jus-unset{
  justify-content: unset !important;
}

.form-floating>.form-control:not(:placeholder-shown)~label::after{
  background-color: transparent !important;
}